export function countDynamic(currentValue, previousValue) {
    if (currentValue != null && currentValue !== "" && previousValue != null && previousValue !== "") {
        let result = (currentValue - previousValue) / previousValue * 100;

        if (Number.isInteger(result)) {
            return result + "%";
        }
        return result.toFixed(2) + "%";
    }
    return '—';
}