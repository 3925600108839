import api from "@/modules/api";

export async function getRegionControlsByPeriod(domain, regionCode, periodCode, cznMode){
    return api.get(`/indicators/controls/${domain}/region/${regionCode}${periodCode ? '/'+periodCode : ''}${cznMode ? '?cznMode=' + cznMode : ''}`)
}

export async function getCznControlsByPeriod(domain, cznId, periodCode){
    return api.get(`/indicators/controls/${domain}/czn/${cznId}${periodCode ? '/'+periodCode : ''}`)
}

export async function getControlsByIndicatorFormId(indicatorFormId){
    return api.get(`/indicators/controls/form/${indicatorFormId}`)
}

export async function saveControls(indicators){
    return api.postJson(`/indicators/controls`, indicators)
}