<template>
  <v-dialog v-model="dialog" max-width="1200px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div class="edit" v-bind="attrs" v-on="on">
        <v-icon class="icon-item icon-note-edit-outline"></v-icon>
      </div>
    </template>
    <ClientCentricityChecklistEditDialog
        v-if="dialog"
        :config="config"
        :domain="domain"
        :period="period"
        :period-span="periodSpan"
        :prefix="prefix"
        :controller-roles="controllerRoles"
        :editor-roles="editorRoles"
        @updated="$emit('updated'); dialog=false;"
        @close="dialog=false;"
    />
  </v-dialog>
</template>

<script>

import ClientCentricityChecklistEditDialog from "@/components/icc_new/ClientCentricityChecklistEditDialog";

export default {
  name: "ClientCentricityEditButton",
  components: {ClientCentricityChecklistEditDialog},
  props: ['config', 'domain', 'period', 'periodSpan', 'prefix', 'controllerRoles', 'editorRoles'],
  data: () => ({
    dialog: false
  }),
  async beforeMount() {
  }
}
</script>
