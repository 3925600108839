<template>
  <vue-html2pdf
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1020px"
      ref="html2Pdf.friendship.professionalCompetences">
    <section slot="pdf-content">
      <v-container>
        <v-row>
          <v-col xl="2" class="d-flex flex-column">
            <p class="mt-auto mb-0">{{ questionText }}</p>
          </v-col>
          <v-col xl="5">
            <div class="graph-wrap">
              <div class="graph-top text-blue">
                <div>{{ val_s }}<span>/{{ max_val }}</span></div>
                <div>Соискатели <span>{{ answers_count_s }}</span></div>
              </div>
              <GChart
                  type="ColumnChart"
                  :data="diagramData_s"
                  :options="columnChartOptions"
              />
            </div>
          </v-col>
          <v-col xl="5">
            <div class="graph-wrap">
              <div class="graph-top text-orange">
                <div>{{ val_r }}<span>/{{ max_val }}</span></div>
                <div>Работодатели <span> {{ answers_count_r }}</span></div>
              </div>
              <GChart
                  type="ColumnChart"
                  :data="diagramData_r"
                  :options="columnChartOptions"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import {GChart} from "vue-google-charts/legacy";

export default {
  name: "AnketsGraph",
  components: {
    VueHtml2pdf,
    GChart
  },
  props: ['filename', 'questionText', 'val_s', 'val_r', 'max_val', 'soiskAnswers', 'rabAnswers', 'type'],
  data: () => ({
    columnChartOptions: {
      backgroundColor: 'transparent',
      legend: {position: "none"},
      chartArea: {width: '100%', left: 0, top: 0, height: 150},
      vAxis: {
        gridlines: {
          color: 'transparent'
        },
        textPosition: 'none'
      },
      hAxis: {color: '#333', minSpacing: 20},
      annotations: {
        textStyle: {
          fontSize: 14,
          color: '#000',
        }
      },
      bar: {
        groupWidth: 30
      }
    },
  }),
  computed: {
    answers_count_s() {
      return this.getAnswersCount(this.soiskAnswers)
    },

    answers_count_r() {
      return this.getAnswersCount(this.rabAnswers)
    },

    diagramData_s() {
      return this.getDiagramData(this.soiskAnswers)
    },

    diagramData_r() {
      return this.getDiagramData(this.rabAnswers)
    },
  },
  methods: {
    getAnswersCount(answers){
      return answers.reduce(
          (accumulator, currentValue) => {
            return currentValue != null ? accumulator + 1 : accumulator
          }, 0)
    },

    getDiagramData(answers) {
      return this.type == "GOOD_RATIO" ? this.getGoodDiagramData(answers) : this.getYesNoDiagramData(answers);
    },

    getGoodDiagramData(answers) {
      return [
        ["Persons", "Количество", {role: 'annotation'}, {role: 'style'}],
        getRow(1, "#F2994A"),
        getRow(2, "#F2994A"),
        getRow(3, "#F2994A"),
        getRow(4, "#F2994A"),
        getRow(5, "#999999"),
        getRow(6, "#2F80ED"),
        getRow(7, "#2F80ED"),
        getRow(8, "#2F80ED"),
        getRow(9, "#27AE60"),
        getRow(10, "#27AE60"),
      ]

      function getRow(i, color) {
        let r = answers.reduce(
            (accumulator, currentValue) => {
              return currentValue === (i - 1) ? accumulator + 1 : accumulator
            }, 0)
        return [i.toString(), r, r.toString(), color]
      }
    },

    getYesNoDiagramData(answers) {
      return [
          ["Persons", "Количество", {role: 'annotation'}, {role: 'style'}],
          getRow("да", 0, "#27AE60"),
          getRow("не знаю", 1, "#999999"),
          getRow("нет", 2, "#F2994A"),
      ];

        function getRow(text, value, color) {
          const count = answers.filter(answer => answer === value).length;
          return [text, count, count.toString(), color];
        }
    },
  }
}
</script>

<style scoped>

</style>
